body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Titillium Web', sans-serif;
  font-family: 'Vollkorn', serif;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
  font-family: 'Vollkorn', serif;
}

h1 {
  font-family: 'Titillium Web', sans-serif;
  font-weight: black 900;
  font-size: 4rem;
}

h2 {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 700;
  font-size: 2rem;
}

p {
  font-family: 'Titillium Web', sans-serif;
  font-size: 1.5rem;
}

li {
  font-family: 'Titillium Web', sans-serif;
  font-size: 1.5rem;
}

button {
  font-family: 'Titillium Web', sans-serif;
  font-size: .9rem;
}