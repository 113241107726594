.About {
    margin: 0;
    padding: 0;
    background: white;
    height: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.About h2 {
    margin-top: 13vh;
    margin-left: 5vw;
    font-weight: 700;
}

.About .Conatiner {
    display: flex;
    margin: 0 15vh;
    align-items: center;
}

.About .Conatiner p {
    margin-left: 5vw;
}

.About .Conatiner .Goat {
    align-items: center;
    justify-content: center;
    height: 60vh;
    right: 10%;
    border-radius: 10px;
}