.Contact {
    height: 120px;
    /* background: #1585b3; */
    background: #368eb2;
    display: flex;
    flex-direction: column;
    /* justify-content: center;/// */
    align-items: center;
    margin: 0;
    padding: .5vw;
}

.Contact p {
    color: white;
    margin: 0;
    font-size: .75rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Contact .Content {
    width: 25vw;
    /* background: green; */
    display: flex;
    flex-direction: column;
}

.Contact .Content .IconLinks {
    display: flex;
    justify-content: space-between;
    color: white;
    margin-bottom: 3vh;
    margin-top: 4vh;
}

.Contact .Content .IconLinks .Link:hover {
    cursor: pointer;
    color: bisque;
}

