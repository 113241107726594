.Home {
    margin: 0;
    padding: 0;
    background: white;
    height: 90vh;
    padding-top: 70px;
    position: relative;
    display: flex;
    /* align-items: center; */
}

.Home .Construction {
    height: 50vh;
    margin-left: 15vw;
    margin-top: 16vh;
    border-radius: 10px;
}

.Home .Laptop {
    height: 25vh;
    margin-left: 8vw;
    /* margin-top: 10vh; */
    border-radius: 10px;
    bottom: 0;
    margin-bottom: 10%;
}

.Home .Container {
    margin-top: 16vh;
    margin-left: 8vw;
    margin-left: 8vw;
    /* justify-content: center; */
}

.Home .Container .Name {
    margin: 0;
    font-weight: 900;
}

.Home .Container .Title {
    font-size: 2rem;
    margin: 0;
    font-weight: 700;
}

.Home .Container .Btn a{
    width: 150px;
    display: flex;
    margin-top: 3vh;
    text-decoration: none;
}

.Home .Container .Btn a button {
    border: solid #368eb2 2px;
    color: white;
    border-radius: 10px;
    font-size: 1.12rem;
    margin-top: 5vh;
    width: 150px;
    background: #368eb2;
    font-weight: 400;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Home .Container .Btn a button:hover {
    border: solid skyblue 2px;
    width: 150px;
    background: skyblue;
    font-weight: 400;
    cursor: pointer;
    color: white; 
}

.Home .Container .Btn a button .Arrow {
    height: 25px;
    margin-left: 6px;
}

