.Projects {
    margin: 0;
    padding: 10;
    background: white;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.Projects h2 {
    margin-top: 13vh;
    margin-left: 5vw;
    font-weight: 700;
}

.Projects .ImageContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Projects .ImageContainer .AllProjects {
    margin: 0 1rem 1rem;
    text-align: center;
}

.Projects .ImageContainer .ProjectCard {
    display: flex;
    flex-direction: column;
}

.Projects .ImageContainer .AllProjects .ProjectCard .ProjectHeader .ProjectTitle {
    margin: 0;
    margin-bottom: 2px;
    text-align: center;
    font-weight: 400;
    /* background-color: green; */
    /* color: white; */
}

.Projects .ImageContainer .AllProjects .ProjectCard .ProjectBody {
    margin: 0;

    text-align: center;
    font-weight: 400;
    /* background-color: blue; */
    display: flex;
    /* color: white; */
}

.Projects .ImageContainer .AllProjects .ProjectCard {
    /* height: 250px; */
    width: 500px;
    padding: 1%;
    background-color: skyblue;
    border: solid skyblue 5px;
    display: flex;
    border-radius: 10px;
}

.Projects .ImageContainer .AllProjects .ProjectCard .Icons{
    display: flex;
    /* align-items: flex-start; */
    /* background-color: yellow; */
}

.Projects .ImageContainer .AllProjects .ProjectCard .Icons .Link{
    margin-left: 1vw;
}

.Projects .ImageContainer .AllProjects .ProjectCard .Icons .Link:hover{
    margin-left: 1vw;
    cursor: pointer;
    fill: bisque;
}

.Projects .ImageContainer .AllProjects .ProjectCard .ProjectDescription{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* padding: 1%; */
    padding-left: 1%;
    /* background-color: orange; */
}

.Projects .ImageContainer .AllProjects .ProjectCard p {
    margin-top: 0;
    text-align: left;
    font-size: 1rem;
}

.Projects .ImageContainer .AllProjects .ProjectCard .Skills {
    text-align: center;
    /* font-style: italic; */
    font-weight: 600;
    margin-top: 5px;
}

.Projects .ImageConatiner .AllProjects .ProjectCard .Picture {
    height: 250px;
    width: 250px;
}

.Thumbnail {
    height: 250px;
    width: 250px;
    /* background: white; */
    border-radius: 10px;
}

.Thumbnail:hover {
    opacity: 50%;
}

.Projects .ArrowCont {
    display: flex;
    justify-content: center;
}

.Projects .ArrowCont a .UpArrow{
    fill: black;
    height: 40px;
    width: 40px;
    margin: 0;
}

.Projects .ArrowCont a .UpArrow:hover{
    fill: #368eb2;
}

