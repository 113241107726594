.Skills {
    margin: 0;
    padding: 0;
    height: 45vh;
    background: white;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.Skills h2 {
    margin-top: 13vh;
    margin-left: 5vw;
}

.Skills .SkillsCont {
    display: flex;
    justify-content: center;
    margin: 0 5rem;
}

.Skills .SkillsCont ul {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Skills .SkillsCont ul li {
    margin-left: .5vw;
    list-style: none;
}