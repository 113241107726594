.NavBar {
    background-color: skyblue;
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 95%;
    position: fixed;
    z-index: 1;
}

.NavBar nav ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.NavBar nav ul li a{
    text-decoration: none;
    color: white;
    margin-left: 1.5rem;
    font-size: 20px;
    font-weight: 700;
}

.NavBar a:hover {
    color: bisque;
    text-decoration: underline 3px dotted;
}